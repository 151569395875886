@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: rgb(0, 0, 0);
  --white: rgb(255, 255, 255);
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 53, 122);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(153, 0, 255, 0.473);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00060728;
}

body {
  font-family: "Ubuntu", sans-serif;
  background: var(--background-color);
  color: var(--text-color);
  font-weight: var(--font-weight);
  transition: background 0.3s ease-in-out, color 0.6s ease-in-out;
  overflow-x: hidden;
}

body.light {
  --background-color: var(--white);
  --text-color: var(--black);
  --font-weight: 400;
}

body.dark {
  --background-color: var(--black);
  --text-color: var(--white);
  --font-weight: 500;
}

nav {
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: var(--text-color);

}

nav a {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--background-color);

  @media screen (max-width: 960px) {
    display: none;
  }
}

.anim {
  width: 100vw;
  height: 100vh;
  background-color: black;
}


.navbarItems {
  display: flex;

  @media screen (max-width: 960px) {
    display: none;
  }
}

.LinkItems {
  display: flex;

  @media screen (max-width: 900px) {
    display: none;
  }
}

.homePageContainer {
  display: flex;
  padding: 5px;
  backdrop-filter: blur(10px);
  justify-content: center;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  z-index: 1;
  position: relative;
  margin-top: 60px;

  @media screen (max-width: 960px) {
    width: 95%;
  }
}

.homePageContainer h1 {
  font-size: 20px;
}


.Layout {
  position: relative;
  width: 100%;

  @media screen (max-width: 960px) {
    display: flex;
  }
}


.projectThumbnail {
  display: flex;
  border-radius: 10px;
  justify-content: center;
  object-fit: cover;
  height: 169px;
  /* width: 210px; */
  flex-wrap: wrap;

  @media only screen and (min-width: 270px) and (max-width: 1160px) {
    height: 250px;
    width: 250px;
  }
}

.projectThumbnail2 {
  display: flex;
  border-radius: 10px;
  justify-content: center;
  object-fit: cover;
  height: 130px;
  /* width: 210px; */
  flex-wrap: wrap;

  @media only screen and (min-width: 270px) and (max-width: 1160px) {
    height: 190px;
    /* width: 250px; */
  }
}


.PostsContainer {
  display: flex;
  flex-wrap: wrap;

  @media screen (max-width: 960px) {
    flex-direction: column;
  }
}

.modalButton {
  color: black;
  padding: 20px;
  margin-left: 5px;
  background-color: lightblue;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 40px;
}

.modalClick {
  padding: 10px;
}

.anim {
  width: 20vw;
  height: 20vh;
  z-index: 999;
  /* background-color: black; */
}

.ModalContentID {
  width: 50px;

}

div.chakra-select__icon-wrapper.css-162mkon {
  display: none;
}

div.chakra-linkbox.css-16kwgao {
  margin-bottom: 30px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding-bottom: 15px;
}

div.chakra-linkbox.css-16kwgao:hover {
  box-shadow: 0 0 30px rgba(199, 239, 255, 0.74);
  transform: scale(1.06);
  -webkit-transition: transform .55s ease-in-out;

}

.modal-backdrop {
  position: relative;
}

.btn-primary {
  width: 400px;
  height: 150px;
  background: none;
  color: rgb(255, 255, 255);
  text-shadow: -1px -1px 0 #000000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  font-weight: 600;
  font-family: Parisienne, cursive;
  font-size: 59px;
  border: none;
  cursor: pointer;
  margin-bottom: -10px;
  border: 2px solid white;
  border-radius: 10px;
  object-fit: cover;
  border: none;
  background-image: url("../assets/images/tranquil2-1.jpeg");
  background-color: none;


}

.btn-primary:hover {
  background-image: url("../assets/images/tranquil2-1.jpeg");
  box-shadow: 0px 0px 10px .2rem rgb(255, 255, 255);
  transform: scale(1.06);
  -webkit-transition: transform .55s ease-in-out;
}

.btn-primary:focus {
  box-shadow: none;
}

.btn-secondary {
  height: 30px;
  margin-top: 20px;
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

.modal-content {
  margin-top: 110px;
  background-color: #000000d2;
  border-radius: 10px;

  @media screen and (max-width: 960px) {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    margin-top: 0px;
    margin-left: -10px;
  }
}

canvas.webgl {
  z-index: 999;
  height: 100px;
  width: 100px;
}

.webgl {
  height: 10px;
}

